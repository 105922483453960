import React, { useState } from "react"
import convert from "color-convert"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"

import {
  Title,
  Input,
  Field,
  Control,
  Span,
  Card,
  CardContent,
  StaticButton,
} from "../../../StyleComponents/styles"
import { ColorImput } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Converter", link: "/units-converter/" },
  {
    name: "Color Conversion",
    link: "/color-converter/",
  },
]
const seeAlsoArray = ["/color-palette-generator"]

function ColorConverter(props) {
  let [forError] = useState("")
  let [inputTypeColor, setinputTypeColor] = useState("#34495e")
  let pageColor
  let nameColor
  let [colorHex, setcolorHex] = useState("34495e")
  let [colorRgb, setcolorRgb] = useState("52,73,94")
  let [colorHsl, setcolorHsl] = useState("210,29,29")
  let [colorCmyk, setcolorCmyk] = useState("45,22,0,63")
  let [colorHsv, setcolorHsv] = useState("210,45,37")
  let [labColor, setlabColor] = useState("30,-2,-15")
  let [colorLch, setcolorLch] = useState("30,15,263")
  let [colorXyz, setcolorXyz] = useState("6,6,11")
  let [colorHwb, setcolorHwb] = useState("210,20,63")
  let divstyle =
    colorHex.indexOf("#") === 0
      ? {
          maxWidth: "500px",
          border: "4px solid " + colorHex,
          margin: "0 auto",
        }
      : {
          maxWidth: "500px",
          border: "4px solid #" + colorHex,
          margin: "0 auto",
        }

  function inputTypeColorC(e) {
    setinputTypeColor(e.target.value)
    let color = e.target.value
    while (color.charAt(0) === "#") {
      color = color.substr(1)
    }
    setcolorHex(color)
    setcolorRgb(convert.hex.rgb(color))
    setcolorHsl(convert.hex.hsl(color))
    setcolorCmyk(convert.hex.cmyk(color))
    setcolorHsv(convert.hex.hsv(color))
    setlabColor(convert.hex.lab(color))
    setcolorLch(convert.hex.lch(color))
    setcolorXyz(convert.hex.xyz(color))
    setcolorHwb(convert.hex.hwb(color))
  }

  pageColor = {
    backgroundColor: colorHex.indexOf("#") === 0 ? colorHex : "#" + colorHex,
  }
  nameColor = {
    color: colorHex.indexOf("#") === 0 ? colorHex : "#" + colorHex,
  }

  function colorHexC(e) {
    let value = e.target.value
    setcolorHex(value)
    setcolorRgb(convert.hex.rgb(value))
    setcolorHsl(convert.hex.hsl(value))
    setcolorCmyk(convert.hex.cmyk(value))
    setcolorHsv(convert.hex.hsv(value))
    setlabColor(convert.hex.lab(value))
    setcolorLch(convert.hex.lch(value))
    setcolorXyz(convert.hex.xyz(value))
    setcolorHwb(convert.hex.hwb(value))
    if (value.charAt(0) === "#") {
      if (value.length === 4) {
        setinputTypeColor(
          "#" + value[1] + value[1] + value[2] + value[2] + value[3] + value[3]
        )
      } else {
        setinputTypeColor(value)
      }
    } else if (value.length === 3) {
      setinputTypeColor(
        "#" + value[0] + value[0] + value[1] + value[1] + value[2] + value[2]
      )
    } else {
      setinputTypeColor("#" + value)
    }
  }

  function colorRgbC(e) {
    let value = e.target.value
    setcolorRgb(value)
    let arrayValue = value.split(",")
    if (isNaN(arrayValue[0]) || isNaN(arrayValue[1]) || isNaN(arrayValue[2])) {
      setcolorXyz("")
      setcolorLch("")
      setcolorHwb("")
      setcolorHsv("")
      setcolorCmyk("")
      setcolorHsl("")
      setlabColor("")
      setcolorHex("")
    } else {
      setcolorHex(convert.rgb.hex(arrayValue))
      setcolorHsl(convert.rgb.hsl(arrayValue))
      setcolorCmyk(convert.rgb.cmyk(arrayValue))
      setcolorHsv(convert.rgb.hsv(arrayValue))
      setlabColor(convert.rgb.lab(arrayValue))
      setcolorLch(convert.rgb.lch(arrayValue))
      setcolorXyz(convert.rgb.xyz(arrayValue))
      setcolorHwb(convert.rgb.hwb(arrayValue))
      setinputTypeColor("#" + convert.rgb.hex(arrayValue))
    }
  }

  function colorHslC(e) {
    setcolorHsl(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")
    if (isNaN(arrayValue[0]) || isNaN(arrayValue[1]) || isNaN(arrayValue[2])) {
      setcolorXyz("")
      setcolorLch("")
      setcolorHwb("")
      setcolorHsv("")
      setcolorCmyk("")
      setcolorRgb("")
      setlabColor("")
      setcolorHex("")
    } else {
      setcolorRgb(convert.hsl.rgb(arrayValue))
      setcolorHex(convert.hsl.hex(arrayValue))
      setcolorCmyk(convert.hsl.cmyk(arrayValue))
      setcolorHsv(convert.hsl.hsv(arrayValue))
      setlabColor(convert.hsl.lab(arrayValue))
      setcolorLch(convert.hsl.lch(arrayValue))
      setcolorXyz(convert.hsl.xyz(arrayValue))
      setcolorHwb(convert.hsl.hwb(arrayValue))
      setinputTypeColor("#" + convert.hsl.hex(arrayValue))
    }
  }

  function colorCmykC(e) {
    setcolorCmyk(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")

    if (
      isNaN(arrayValue[0]) ||
      isNaN(arrayValue[1]) ||
      isNaN(arrayValue[2]) ||
      isNaN(arrayValue[3])
    ) {
      setcolorXyz("")
      setcolorLch("")
      setcolorHwb("")
      setcolorHsv("")
      setcolorHsl("")
      setcolorRgb("")
      setlabColor("")
      setcolorHex("")
    } else {
      setcolorHsl(convert.cmyk.hsl(arrayValue))
      setcolorRgb(convert.cmyk.rgb(arrayValue))
      setcolorHex(convert.cmyk.hex(arrayValue))
      setcolorHsv(convert.cmyk.hsv(arrayValue))
      setlabColor(convert.cmyk.lab(arrayValue))
      setcolorLch(convert.cmyk.lch(arrayValue))
      setcolorXyz(convert.cmyk.xyz(arrayValue))
      setcolorHwb(convert.cmyk.hwb(arrayValue))
      setinputTypeColor("#" + convert.cmyk.hex(arrayValue))
    }
  }
  function colorHsvC(e) {
    setcolorHsv(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")
    if (isNaN(arrayValue[0]) || isNaN(arrayValue[1]) || isNaN(arrayValue[2])) {
      setcolorXyz("")
      setcolorLch("")
      setcolorHwb("")
      setcolorCmyk("")
      setcolorHsl("")
      setcolorRgb("")
      setlabColor("")
      setcolorHex("")
    } else {
      setcolorCmyk(convert.hsv.cmyk(arrayValue))
      setcolorHsl(convert.hsv.hsl(arrayValue))
      setcolorRgb(convert.hsv.rgb(arrayValue))
      setcolorHex(convert.hsv.hex(arrayValue))
      setlabColor(convert.hsv.lab(arrayValue))
      setcolorLch(convert.hsv.lch(arrayValue))
      setcolorXyz(convert.hsv.xyz(arrayValue))
      setcolorHwb(convert.hsv.hwb(arrayValue))
      setinputTypeColor("#" + convert.hsv.hex(arrayValue))
    }
  }
  function labColorC(e) {
    setlabColor(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")
    let arr = [
      Number(arrayValue[0]),
      Number(arrayValue[1]),
      Number(arrayValue[2]),
    ]
    if (isNaN(arr[0]) || isNaN(arr[1]) || isNaN(arr[2])) {
      setcolorXyz("")
      setcolorLch("")
      setcolorHwb("")
      setcolorHsv("")
      setcolorCmyk("")
      setcolorHsl("")
      setcolorRgb("")
      setcolorHex("")
    } else {
      setcolorHsv(convert.lab.hsv(arr))
      setcolorCmyk(convert.lab.cmyk(arr))
      setcolorHsl(convert.lab.hsl(arr))
      setcolorRgb(convert.lab.rgb(arr))
      setcolorHex(convert.lab.hex(arr))
      setcolorLch(convert.lab.lch(arr))
      setcolorXyz(convert.lab.xyz(arr))
      setcolorHwb(convert.lab.hwb(arr))
      setinputTypeColor("#" + convert.lab.hex(arr))
    }
  }
  function colorLchC(e) {
    setcolorLch(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")
    let arr = [
      Number(arrayValue[0]),
      Number(arrayValue[1]),
      Number(arrayValue[2]),
    ]
    if (isNaN(arr[0]) || isNaN(arr[1]) || isNaN(arr[2])) {
      setcolorXyz("")
      setcolorHwb("")
      setlabColor("")
      setcolorHsv("")
      setcolorCmyk("")
      setcolorHsl("")
      setcolorRgb("")
      setcolorHex("")
    } else {
      setlabColor(convert.lch.lab(arr))
      setcolorHsv(convert.lch.hsv(arr))
      setcolorCmyk(convert.lch.cmyk(arr))
      setcolorHsl(convert.lch.hsl(arr))
      setcolorRgb(convert.lch.rgb(arr))
      setcolorHex(convert.lch.hex(arr))
      setcolorXyz(convert.lch.xyz(arr))
      setcolorHwb(convert.lch.hwb(arr))
      setinputTypeColor("#" + convert.lch.hex(arr))
    }
  }
  function colorXyzC(e) {
    setcolorXyz(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")
    let arr = [
      Number(arrayValue[0]),
      Number(arrayValue[1]),
      Number(arrayValue[2]),
    ]
    if (isNaN(arr[0]) || isNaN(arr[1]) || isNaN(arr[2])) {
      setcolorHwb("")
      setcolorLch("")
      setlabColor("")
      setcolorHsv("")
      setcolorCmyk("")
      setcolorHsl("")
      setcolorRgb("")
      setcolorHex("")
    } else {
      setcolorLch(convert.xyz.lch(arr))
      setlabColor(convert.xyz.lab(arr))
      setcolorHsv(convert.xyz.hsv(arr))
      setcolorCmyk(convert.xyz.cmyk(arr))
      setcolorHsl(convert.xyz.hsl(arr))
      setcolorRgb(convert.xyz.rgb(arr))
      setcolorHex(convert.xyz.hex(arr))
      setcolorHwb(convert.xyz.hwb(arr))
      setinputTypeColor("#" + convert.xyz.hex(arr))
    }
  }
  function colorHwbC(e) {
    setcolorHwb(e.target.value)
    let value = e.target.value
    let arrayValue = value.split(",")
    let arr = [
      Number(arrayValue[0]),
      Number(arrayValue[1]),
      Number(arrayValue[2]),
    ]
    if (isNaN(arr[0]) || isNaN(arr[1]) || isNaN(arr[2])) {
      setcolorXyz("")
      setcolorLch("")
      setlabColor("")
      setcolorHsv("")
      setcolorCmyk("")
      setcolorHsl("")
      setcolorRgb("")
      setcolorHex("")
    } else {
      setcolorXyz(convert.hwb.xyz(arr))
      setcolorLch(convert.hwb.lch(arr))
      setlabColor(convert.hwb.lab(arr))
      setcolorHsv(convert.hwb.hsv(arr))
      setcolorCmyk(convert.hwb.cmyk(arr))
      setcolorHsl(convert.hwb.hsl(arr))
      setcolorRgb(convert.hwb.rgb(arr))
      setcolorHex(convert.hwb.hex(arr))
      setinputTypeColor("#" + convert.hwb.hex(arr))
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Color Converter"
        description="Color converter, Sinple RGB, HEX, CMY, HSV conversion ,convert Hsl,HSL,convert HWB,convert Cmyk,convert NCOL,NCOL,Cmyk,Hsl, RGB To CMY, rgb to hex, hex to rgb, cmy to rgb hex to cmy, cmy to rgb, cmy to hex,RGB to HSV, hsv to cmy, hsv to rgb, hsv to hex, hex to hsv, rgb to hsv, color conveter, RGB, HEX, HSL, HWB, CMYK, NCOL, CMY, HSV easy colors converter."
        keywords={[
          "Color Converter,random color,convert Hsl,HSL,convert HWB,convert Cmyk,convert NCOL,NCOL,Cmyk,Hsl, RGB To CMY, rgb to hex, hex to rgb, cmy to rgb hex to cmy, cmy to rgb, cmy to hex,RGB to HSV, hsv to cmy, hsv to rgb, hsv to hex, hex to hsv, rgb to hsv, color conveter, convert colos,Cmyk color,Hsv color,Lab color,Lch color,Xyz color,Hwb color.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Color Conversion</Title>

        <section className="container mb-4">
          <Card style={divstyle}>
            <CardContent>
              <ColorImput
                type="color"
                value={inputTypeColor}
                onChange={inputTypeColorC}
              />
              <div>{forError}</div>
              <Span>Simple Convert Colors just input your color</Span>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Hex</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorHex}
                    onChange={colorHexC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Rgb</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorRgb}
                    onChange={colorRgbC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Hsl</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorHsl}
                    onChange={colorHslC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Cmyk</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorCmyk}
                    onChange={colorCmykC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Hsv</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorHsv}
                    onChange={colorHsvC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Lab</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={labColor}
                    onChange={labColorC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Lch</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorLch}
                    onChange={colorLchC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Xyz</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorXyz}
                    onChange={colorXyzC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
              <Field>
                <Control>
                  <StaticButton style={nameColor}>
                    <strong>Hwb</strong>
                  </StaticButton>
                </Control>
                <Control className="expandedWidth">
                  <Input
                    radius="0"
                    type="text"
                    value={colorHwb}
                    onChange={colorHwbC}
                  />
                </Control>
                <Control>
                  <StaticButton
                    bottomLeftRadius="0"
                    TopLeftRadius="0"
                    bottomRightRadius="4px"
                    topRightRadius="4px"
                    style={pageColor}
                  ></StaticButton>
                </Control>
              </Field>
            </CardContent>
          </Card>
        </section>
        <br />
        <h3>RGB Color</h3>
        <p>
          RGB color system combination of the Red, Green, and Blue colors. The
          red, green, and blue use 8 bits each, which have integer values from 0
          to 255.
          <strong>RGB = Red, Green, Blue</strong>
        </p>
        <br />
        <h3>HEX Color</h3>
        <p>
          Hex (hexadecimal) is a six-digit number used in HTML, CSS, SVG, and
          other computing applications to represent colors.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default ColorConverter
